<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h1 class="text-center">About Us</h1>
        <div>
          <p>
            Vaporfly company is based in California and has over 10 years of experience in the vaping industry. It
            specializes in the wholesale and white labeling of cannabis devices.
          </p>
          <p>
            Our partners are the leading Chinese manufacturers that produce vaporizers and oil cartridges. We’ve been
            building these partnerships for years and therefore we are the best resource for quality products from them.
          </p>
          <p>
            Our laboratories for packing, device, and oil testing are located in the USA and China. Having such a big
            chain of suppliers makes us always ready to provide the best solutions for cannabis enterprises. And our
            international team of highly-qualified engineers, QA agents, and logistic specialists are responsible for
            ensuring that you get exactly what you wanted on time. Vaporfly follows the idea to deliver the finest in
            vaporizers to the cannabis vaping industry.
          </p>
        </div>
        <h3 class="text-center text-color-light-green p-t-1" style="font-weight: normal">More about Vaporfly</h3>
        <SocialLinks class="text-center p-tb-1" />
      </div>
    </div>
  </div>
</template>

<script>
import { MICRO_LOCAL_BUSINESS, setMicroMarkup, removeMicroMarkup } from 'Lib/MicroMarkup';
import MetaMixin from 'Lib/mixins/MetaMixin';
import SocialLinks from 'Components/SocialLinks';

export default {
  name: 'AboutUs',
  components: { SocialLinks },
  mixins: [MetaMixin],
  data() {
    return {
      departments: [
        {
          title: 'US department',
          contactors: [
            {
              name: 'Lo Saelee',
              position: 'Account manager',
              email: 'lo.salee@vaporfly.com',
              address: '10461 Old Placerville Rd. Suite 150. Sacramento, CA 95827',
              mobile: '+1(530)515-1817',
            },
          ],
        },
        {
          title: 'Canada department',
          contactors: [
            {
              name: 'Valeriia Baliura',
              position: 'Business Development Manager',
              email: 'valeriia.baliura@vaporfly.com',
              address: '10 Park Lawn Rd, Toronto, ON Canada M8V 0H9',
              mobile: '+1(437)332-0082',
            },
          ],
        },
        {
          title: 'EU department',
          contactors: [
            {
              _nameLink: 'https://andi.global/en/',
              name: 'Andi Europe',
              email: 'sales@vaporfly.com',
              address: 'Andi Europe d.o.o. Pul Vele Crikve 1 51000 Rijeka Croatia',
              mobile: '+385(91)605-6633',
            },
          ],
        },
      ],
    };
  },
  beforeMount() {
    this.$store.dispatch('setBreadcrumbs', [{ to: this.$route.path, title: 'About Us' }]);
    setMicroMarkup(MICRO_LOCAL_BUSINESS);
  },
  beforeDestroy() {
    removeMicroMarkup(MICRO_LOCAL_BUSINESS);
  },
};
</script>

<style type="text/css" lang="scss" scoped>
p {
  line-height: 2;
  font-size: larger;
  text-indent: 1em;
}
@media only screen and (max-width: 640px) {
  p {
    line-height: 2;
    font-size: unset;
    padding: 0 5px;
  }
}
</style>
